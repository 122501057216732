<nav class="navbar sticky-top navbar-primary bg-primary text-light">
  <a class="navbar-brand text-light" routerLink="/home">Deepraft</a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
    <button routerLink="/home" type="button" class="btn btn-warning btn-sm font-weight-bold">&times;</button>
</nav>

<div class="content p-2" role="main">
  <img src="../../assets/logo.jpg" class="img-fluid" alt="Logo">
  <p>
  DEEP-RAFT is an educational and outreach endeavor in four Eastern Europe countries (Greece, Georgia, Kazhakhstan, Ukraine) for patients, doctors and healthcare policy makers. It aims at raising awareness, educating and affecting systemic change on diagnosis and treatment of AF/VTE.
</p>
<p>
DEEP-RAFT innovates through a co-creative approach for educational design and an active approach to learning and awareness building.
</p>
<p>
  DEEP-RAFT creates evidence based, medically valid interactive educational material in the form of virtual patients and branching educational vignettes for doctors and patients. This content drives experiential workshops and infodays at key international conferences and dates.
</p>
<p>
  DEEPRAFT Content and events are created with the help of heathcare system stakeholders (Specialist doctors, patients, healthcare policy makers) custom to healthcare, cultural and technological particulars of each participating country. Active learning principles such as Scenario/Problem Based Learning facilitate hands-on doctors’ education and participatory patient awareness.
</p>
<p>
  DEEPRAFT’s multilayered, iterative evaluation plan covers all levels of outcomes from activity participation to community healthcare impact ensuring DEEP-RAFT’s systemic and emergent healthcare impact through evidence based, co-creative, active learning, participatory approaches.
</p>

<p>
  <a href="https://www.deepraft.com">https://www.deepraft.com</a>
</p>

</div>