import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-play',
  templateUrl: './play.component.html',
  styleUrls: ['./play.component.scss']
})
export class PlayComponent implements OnInit, OnDestroy {
  vpid: number = 42;
  choice: number;
  entry: any;
  sub: any;
    nodes: Array<any>;
    pathway: Array<any> = [];
    node: any;
    progress: number;
    content: any;
    pathwaym: any;
  closeResult: string;


  constructor(
  private modalService: NgbModal,
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  goToAction() {
    this.node = this.nodes.find(item => item.field_node_id == this.choice);
    this.choice = null;
    // tslint:disable-next-line:max-line-length
    this.progress = this.node.field_progress_indicator.split(" ")[0];
    let num = (Math.floor(Date.now()) - this.pathway[this.pathway.length - 1].timestamp) / 1000;
    this.pathway[this.pathway.length - 1].timenode = Math.round(num * 10) / 10;
    this.node['timestamp'] = Math.floor(Date.now());
    let n = this.node;
    this.pathway.push(n); // push the node in the pathway
    window.scroll(0, 0);
  }

  onSelectionChange(entry: any) {
    this.choice = entry;
  }


  ngOnInit() {

    this.vpid = this.route.snapshot.params.vpid;

    const vpsrc = 'assets/vps/' + this.vpid + '.json';
    this.sub = this.http.get(vpsrc)
      .subscribe( (data:any[]) => {
        const vpdata = data[0];
        // Read the result field from the JSON response.
        this.nodes = vpdata['vpnodes'];
        this.node = vpdata['vpnodes'][0];
        this.progress = this.node.field_progress_indicator.split(" ")[0];
        this.node['timestamp'] = Math.floor(Date.now());
        this.pathway.push(this.node); // push the node in the pathway
      });
  }

 ngOnDestroy() {
    this.sub.unsubscribe();
  }

  openp(pathwaym: any) {
      this.modalService.open(pathwaym).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
  open(content: any) {
      // console.log(this.node);
      this.modalService.open(content).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }

    private getDismissReason(reason: any): string {
      if (reason === ModalDismissReasons.ESC) {
        return 'by pressing ESC';
      } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
        return 'by clicking on a backdrop';
      } else {
        return  `with: ${reason}`;
      }
    }


  } // end class

