<nav class="navbar sticky-top navbar-primary bg-primary text-light">
  <a class="navbar-brand text-light" routerLink="/home">Deepraft</a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <button routerLink="/home" type="button" class="btn btn-warning btn-sm font-weight-bold">&times;</button>
</nav>

<div class="container mb-5 mt-2 p-1">

<p *ngIf="node">
  <ngb-progressbar type="primary" [value]="progress" [striped]="true" [animated]="true"><i>{{ progress }}</i>
  </ngb-progressbar>
</p>

<div class="card mb-0" *ngIf="node">
  <div class="card-body">
    <h5 class="card-title">{{ node?.field_node_title }}</h5>
    <h6 class="card-subtitle text-muted">{{ node?.field_node_subtitle }}</h6>
  </div>

  <div class="node-image">
    <img
      style="width: 100%;"
      src="https://app.deepraft.com/{{ node.field_image }}"
      alt="Card image">
  </div>

  <div class="card-body">
    <p class="card-text" [innerHtml]="node.field_content">
    </p>
  </div>

  <div class="card-footer" *ngIf="node.buttons.length">
    <strong>Τι θέλετε να κάνετε στη συνέχεια;</strong>
    <div class="custom-control custom-radio">
      <label class="mt-3 mb-3 " *ngFor="let b of node.buttons; let i=index" >
         <input name="radio-stacked" type="radio" class=""
         (change)="onSelectionChange(b.field_target_node_id)"
         value="b.field_target_node_id">
         <span class="custom-control-indicator">&nbsp;</span>
         <span class="custom-control-description">{{ b.field_label }}</span>
       </label>
     </div>
   </div>

  <div class="card-body">
    <button type="button"
    [disabled]="!this.choice"
    (click)="goToAction()"
    class="text-wrap mt-3 mb-3 btn btn-primary btn-block">Συνέχεια</button>
  </div>
</div>
<p>&nbsp;</p>

<ng-template #pathwaym let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">Review your pathway</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
      <div *ngFor="let n of pathway; let i=index" class="pathway-node mb-5 pl-2">
          <i class="fa fa-circle-o"></i>  {{ n.field_node_title }}
            <span *ngIf="n.timenode" class="badge badge-success">{{ n.timenode }} seconds</span>
            <span *ngIf="!n.timenode" class="badge badge-warning"> Είστε εδώ</span>
      </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="c('Close click')">Κλείσιμο</button>
  </div>
</ng-template>


<ng-template #content let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">Πρόσθετες πληροφορίες</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>{{ node.field_support_information }}</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="c('Close click')">Close</button>
  </div>
</ng-template>

<nav class="navbar fixed-bottom navbar-dark bg-info" *ngIf="node">
    <button
    (click)="openp(pathwaym)"
    class="btn btn-link btn-sm text-light"><strong>Review your pathway</strong></button>
    <div></div>
    <button
    (click)="open(content)"
    [disabled]="!node.field_support_information"
    class="btn btn-link btn-sm text-light"><strong>Πρόσθετες πληροφορίες</strong></button>
</nav>