<nav class="navbar sticky-top navbar-primary bg-primary text-light">
  <a class="navbar-brand text-light" href="#">Deepraft</a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <button routerLink="/about" type="button" class="btn btn-warning btn-sm">About</button>
</nav>


<div class="content" role="main">

  <div
  *ngIf="cookieValue=='on'"
  class="alert alert-dismissible alert-primary">
    <button type="button" class="close"
    (click)="dismissAddToHomeAlert()"
    data-dismiss="alert">&times;</button>
    <strong>You can add</strong> this app to your home screen for easier access.
  </div>

  <div class="card border-warning m-2 mb-5" *ngFor="let n of data; let i=index">
    <div class="card-header">
      <span *ngIf="n.field_author_fu">Δημιουργός: {{ n.field_author_fu }}</span>
      <span *ngIf="!n.field_author_fu">Uknown author</span>
    </div>
    <div style="overflow: hidden; height: 180px;">
      <img *ngIf="n.field_list_image"
      style="width: 100%"
      src="http://app.deepraft.com/{{ n.field_list_image }}"
      alt="Card image">

      <img *ngIf="!n.field_list_image"
      style="width: 100%"
      src="data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%22318%22%20height%3D%22180%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20318%20180%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_158bd1d28ef%20text%20%7B%20fill%3Argba(255%2C255%2C255%2C.75)%3Bfont-weight%3Anormal%3Bfont-family%3AHelvetica%2C%20monospace%3Bfont-size%3A16pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_158bd1d28ef%22%3E%3Crect%20width%3D%22318%22%20height%3D%22180%22%20fill%3D%22%23777%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%22129.359375%22%20y%3D%2297.35%22%3EImage%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E"
      alt="Card image">
    </div>

    <div class="card-body">
      <h4 class="card-title">{{ n.title }}</h4>
      <p *ngIf="n.field_short_description"
      class="card-text">{{ n.field_short_description}}</p>
      <p class="text-center">
        <span class="badge badge-light">
          ~ {{ n.field_duration }} λεπτά
        </span>
        <button [routerLink]="['/play/', n.nid]" type="button" class="btn btn-primary btn-lg btn-block">Ξεκινήστε!</button>

      </p>
    </div>
  </div>

</div>

