import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  isLoading: boolean;
  data: any;
  cookieValue: string;

  constructor(
    private http: HttpClient,
    private cookieService: CookieService
  ) {
  }

  dismissAddToHomeAlert() {
    this.cookieService.set('add-to-home','off');
    this.cookieValue = 'off';
  }

  ngOnInit(): void {

    this.cookieValue = this.cookieService.get('add-to-home') ? this.cookieService.get('add-to-home') : 'on';

    // var cors_api_host = 'cors-anywhere.herokuapp.com';
    // var cors_api_url = 'https://' + cors_api_host + '/http://app.deepraft.com/vp/all';
    var cors_api_url = './assets/vps.json';
     this.http.get(cors_api_url)
      .subscribe( (data:any[]) => {
        console.log(data);
        var nids = ['42','43','44','45','48','49'];
        this.data = data.filter(item => nids.includes(item.nid) === true);
     });
  }

}
